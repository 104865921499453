import './css/App.css';
import logoB from './images/logo.png';
import bd from './images/text_flat_tb.png';
import gif from './images/pom.gif';
import icon_design from './images/icon_design_t.png';
import icon_tech from './images/icon_tech_t.png';
import icon_outreach from './images/icon_mark_t.png';
import icon_bizdev from './images/icon_bd_t.png';
import icon_ops from './images/icon_ops_t.png';
import discord from './images/discord.png';
import telegram from './images/telegram.png';
import twitter from './images/twitter.png';

function App() {
  return (
    <div className="body">
      <div className="hero-container">
        <div className="nav">
          <a href="#"><img src={logoB} alt="Logo containing a colorful shield with a capital B" /></a>
          <a id="ju" href="https://discord.gg/McqF7vyCWx" target="_blank">Join us</a>
        </div>
        <div className="hero-child-content2">
          <div className="hcc-pic">
            <img src={bd} alt="Picture with the text BEAST DAO"/>
          </div>
          <div className="hcc-text">
            <h2>We are on the mission<br></br>to provide a way for people to come and work together<br></br>on impactful projects they care about, having access<br></br>to needed resources, expertise and connections.</h2>
          </div>
        </div>
      </div>
      <div id="wib" className="info-section">
        <div className="wib-container">
          <p>BEAST aims to be as powerful as a16z, as productive as Consensys, as useful as Gnosis, while in the form of a cooperative DAO.</p>
          <p>BEAST DAO is a members-owned initiative, revolutionizing how we collaborate. <br></br><b>It's your portal to co-create, fund and govern impactful projects.</b></p>
        </div>
      </div>
      <div id="wwd" class="info-section">
        <div class="info-section-container">
          <div class="wwd-c1">
            <p>To fulfill our mission, we are forming an ever-growing community of technical and non-technical builders set to create tooling for Web3</p>
          </div>
          <div class="wwd-c2">
            <div class="wwd-c2l">
              <div class="wwd-c2l-t">
                <p>Now we develop <b>Proof-of-Membership (PoM) - an authentication system for crypto communities and their members</b>. With PoM available as a free public good, both web3 communities and their members can authenticate themselves and make crypto space safer for the next billion users.</p>
              </div>
              <div class="wwd-c2l-b">
                <a href="https://testnet.pom.cards/" target="_blank">Try PoM</a>
              </div>
            </div>
            <div class="wwd-c2r">
              <img src={gif} alt="A gif with sample membership catd NFTs highlighting personalisation and customisation features of dynamic NFTs"/>
            </div>
          </div>
        </div>
      </div>
      <div id="wwa" class="info-section">
        <div class="info-section-container">
          <div class="wwa-c1">
            <p>We are just like you:<br></br>we like to do things together and enjoy achievements!</p>
            <p id="highlight">We come from 5 domains needed for BEAST success:</p>
          </div>
          <div class="wwa-c2">
            <div class="wwa-c2-i">
              <img src={icon_design} alt="Icon representing design team of BEAST DAO"/>
              <p>Design</p>
            </div>
            <div class="wwa-c2-i">
              <img src={icon_tech} alt="Icon representing developers team of BEAST DAO"/>
              <p>Tech</p>
            </div>
            <div class="wwa-c2-i">
              <img src={icon_outreach} alt="Icon representing marketing team of BEAST DAO"/>
              <p>Outreach</p>
            </div>
            <div class="wwa-c2-i">
              <img src={icon_bizdev} alt="Icon representing business development team of BEAST DAO"/>
              <p>BizDev</p>
            </div>
            <div class="wwa-c2-i">
              <img src={icon_ops} alt="Icon representing operations and strategy team of BEAST DAO"/>
              <p>Ops & Strategy</p>
            </div>
          </div>
        </div>
      </div>
      <div id="gi" class="info-section">
        <div class="info-section-container">
          <div class="gi-c1">
            <p>GET INVOLVED!</p>
          </div>
          <div class="gi-c2">
            <div class="gi-c2-a">
              <div class="gi-c2-a-c1">
                <p>Join our Discord</p>
              </div>
              <div class="gi-c2-a-c2">
                <a href="https://discord.gg/McqF7vyCWx" target="_blank"><img src={discord} alt="Join our Discord server"/></a>
              </div>
            </div>
            <div class="gi-c2-a">
              <div class="gi-c2-a-c1">
                <p>Stay updated with our socials</p>
              </div>
              <div class="gi-c2-a-c2">
                <a href="https://t.me/beast_dao" target="_blank"><img src={telegram} alt="Join our Telegram channel"/></a>
                <a href="https://twitter.com/BEAST_DAO" target="_blank"><img src={twitter} alt="Follow us on Twitter"/></a>
              </div>
            </div>
            <div class="gi-c2-a">
              <div class="gi-c2-a-c1">
                <p>Contribute to Proof of Membership service</p>
              </div>
              <div class="gi-c2-a-c2" id="pom">
                <a href="https://pom.cards/" target="_blank">PoM Website</a>
              </div>
            </div>
          </div>
          <div class="gi-c3">
            <a href="mailto:beastcoopdao@gmail.com">Contact us</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
